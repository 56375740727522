import 'whatwg-fetch';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

// Prevents error in console from rails-ujs about already running
// This is a workaround for rails-ujs auto-starting when imported
// depending on how it was bundled
if (!window._rails_loaded) {
  Rails.start();
}

Turbolinks.start();

document.addEventListener('turbolinks:before-render', () => {
  document.getElementsByTagName('html')[0].classList.remove('no-scroll');
});
